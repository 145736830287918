import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchVouchersAsync, incrementPage, resetPage, setCategory } from '../../store/vouchers/showVoucherShopeeSlice';
import formatCurrency from '../utils/formatCurrency';
import timestampToDDMM from '../utils/timestampToDDMM';

const randomLinks = [
  'https://shopee.vn/shop/127691603',
  'https://shopee.vn/shop/639793298',
  'https://shopee.vn/shop/55064572',
  'https://shopee.vn/shop/148910286',
  'https://shopee.vn/shop/89960894',
  'https://shopee.vn/shop/111138057',
  'https://shopee.vn/shop/26947756',
  'https://shopee.vn/shop/639673459',
  'https://shopee.vn/shop/131201661',
  'https://shopee.vn/shop/752342657',
  'https://shopee.vn/shop/75810249',
  'https://shopee.vn/shop/189431669',
  'https://shopee.vn/shop/267127101',
  'https://shopee.vn/shop/55771655',
  'https://shopee.vn/shop/189615586',
  'https://shopee.vn/shop/17728570',
  'https://shopee.vn/shop/175753395',
  'https://shopee.vn/shop/106909158',
  'https://shopee.vn/shop/71981162',
  'https://shopee.vn/shop/518445856',
  'https://shopee.vn/shop/61922582',
  'https://shopee.vn/shop/44806875',
  'https://shopee.vn/shop/392896126',
  'https://shopee.vn/shop/1325987084',
  'https://shopee.vn/shop/147191228',
  'https://shopee.vn/shop/27495213',
  'https://shopee.vn/shop/212004800',
  'https://shopee.vn/shop/1041184627',
  'https://shopee.vn/shop/37147137',
  'https://shopee.vn/shop/37251700',
  'https://shopee.vn/shop/68988783',
  'https://shopee.vn/shop/24710134',
  'https://shopee.vn/shop/42063380',
  'https://shopee.vn/shop/62340571',
  'https://shopee.vn/shop/58542133',
  'https://shopee.vn/shop/1021343332',
  'https://shopee.vn/shop/106933589',
  'https://shopee.vn/shop/49755456',
  'https://shopee.vn/shop/844495450',
  'https://shopee.vn/shop/71009635',
  'https://shopee.vn/shop/82611496',
  'https://shopee.vn/product/37251700/1335573348',
  'https://shopee.vn/product/111138057/2855276371',
  'https://shopee.vn/product/111138057/8587034871',
  'https://shopee.vn/product/26947756/2820087763',
  'https://shopee.vn/product/26947756/5657384313',
  'https://shopee.vn/product/71009635/29201425271',
  'https://shopee.vn/product/1041184627/19191865723',
  'https://shopee.vn/product/37251933/591989399',
  'https://shopee.vn/product/421677475/10707015149',
  'https://shopee.vn/product/446089250/19492974087',
  'https://shopee.vn/product/24710134/7044949931',
  'https://shopee.vn/product/68988783/20476754847',
  'https://shopee.vn/product/175753395/6065001847',
  'https://shopee.vn/product/106909158/5027646135',
  'https://shopee.vn/product/392896126/2923606392',
  'https://shopee.vn/product/147191228/2278111046',
  'https://shopee.vn/product/89960894/5435391392',
];

const getRandomLink = () => {
  return randomLinks[Math.floor(Math.random() * randomLinks.length)];
};

const getRandomLinkWithIdUser = (idUser) => {
  const randomLink = getRandomLink();
  const targetUrl = `https://s.shopee.vn/an_redir?origin_link=${encodeURIComponent(randomLink)}&affiliate_id=17358790110&sub_id=tui3gang-${idUser}`;

  if (idUser === "notlogin") {
    window.open(`/redirect?urlnotlogin=${encodeURIComponent(targetUrl)}`); 
  } else {
    window.open(targetUrl); 
  }
};

const VoucherShopeeList = () => {
  const dispatch = useDispatch();
  const vouchers = useSelector((state) => state.voucherShopee.vouchers);
  const page = useSelector((state) => state.voucherShopee.page);
  const totalPages = useSelector((state) => state.voucherShopee.totalPages);
  const status = useSelector((state) => state.voucherShopee.status);
  const category = useSelector((state) => state.voucherShopee.category);
  const user = useSelector((state) => state.profile.user);
  const idUser = user?.idUser || 'notlogin';
  const [copiedVoucherId, setCopiedVoucherId] = useState(null);

  useEffect(() => {
    dispatch(resetPage());
    dispatch(fetchVouchersAsync({ page: 1, category }));
  }, [dispatch, category]);

  const handleCopy = (id, code) => {
    navigator.clipboard.writeText(code);
    setCopiedVoucherId(id);
    setTimeout(() => {
      getRandomLinkWithIdUser(idUser);
    }, 500);
  };

  const handleBuyNow = () => {
    getRandomLinkWithIdUser(idUser);
  };

  const handleLoadMore = () => {
    if (page < totalPages && status === 'succeeded') {
      dispatch(incrementPage());
      dispatch(fetchVouchersAsync({ page: page + 1, category }));
    }
  };

  const handleFilterChange = (category) => {
    dispatch(setCategory(category));
  };

  const filteredVouchers = category === 'Tất cả'
    ? vouchers
    : vouchers.filter(voucher => voucher.promotionCat === category);

  return (
    <div>
      <div className="overflow-x-auto whitespace-nowrap mb-4 py-2">
        <button
          className={`px-4 py-2 m-1 rounded ${category === 'Tất cả' ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'}`}
          onClick={() => handleFilterChange('Tất cả')}
        >
          Tất cả
        </button>
        {['Toàn sàn', 'Shopee Mall', 'Voucher Xtra', 'Freeship', 'Shopee Choice', 'Shopee Video', 'Shopee Live'].map(categoryItem => (
          <button
            key={categoryItem}
            className={`px-4 py-2 m-1 rounded ${category === categoryItem ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'}`}
            onClick={() => handleFilterChange(categoryItem)}
          >
            {categoryItem}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {filteredVouchers.map((voucher) => (
          <div key={voucher._id} className="p-1 border rounded-md shadow-md flex flex-col bg-white">
            <div className="flex">
              {/* Cột trái */}
              <div className="w-1/4 flex flex-col items-center justify-center">
                <img src={voucher.cover} alt="Voucher cover" className="w-11 h-auto rounded-xl mb-1 bg-[#ee4d2d]" />
                <p className="text-[10px]">{voucher.promotionCat}</p>
                <p className="text-[10px]">
                  {voucher.startTime && voucher.startTime > Date.now() ? `Hiệu lực: ${timestampToDDMM(voucher.startTime / 1000)}` : `HSD: ${timestampToDDMM(voucher.endTime / 1000)}`}
                </p>
              </div>

              {/* Cột giữa */}
              <div className="w-full px-2 flex flex-col justify-between">
                <p className="text-md font-bold">
                  {voucher.promotionType === 'percent' ? `Giảm ${voucher.discountAmount}% tối đa ${formatCurrency(voucher.maxDiscount)}` : `Giảm ${formatCurrency(voucher.discountAmount)}`}
                </p>
                <div className="flex justify-between">
                  <div className="items-start">
                    <p className="text-sm text-gray-500">Đơn tối thiểu: {formatCurrency(voucher.minOrderAmount)}</p>
                    <p className="text-sm text-gray-500">
                      Lượt dùng còn: {voucher.percentUsed ? `${voucher.percentUsed}%` : '100%'}
                    </p>
                    {voucher.promotionCode ? (
                      <a href={`https://s.shopee.vn/an_redir?origin_link=https://shopee.vn/voucher-details/${voucher.promotionCode}/${voucher.promotionId}/${voucher.promotionSignature}&affiliate_id=17358790110&sub_id=tui3gang-${idUser}`} rel="noopener noreferrer" className="text-green-700 underline">Chi tiết</a>
                    ) : (
                      <p className="text-xs text-gray-400">#Có sẵn trong ví voucher</p>
                    )}
                  </div>
                  <div className="text-sm flex items-center justify-end">
                    {voucher.promotionCode ? (
                      <button
                        className="text-green-700 font-medium px-4 py-1 rounded bg-green-100 getvoucher"
                        onClick={() => handleCopy(voucher._id, voucher.promotionCode)}
                      >
                        {copiedVoucherId === voucher._id ? 'Đã copy!' : 'Copy mã'}
                      </button>
                    ) : (
                      <button
                        className="text-green-700 font-medium px-4 py-1 rounded bg-green-100 getvoucher"
                        onClick={handleBuyNow}
                      >
                        Dùng mã
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {status === 'loading' && <p>Loading...</p>}
      {page < totalPages && (
        <div className="flex justify-center my-4">
          <button
            className="bg-green-100 font-medium text-green-700 px-4 py-2 rounded"
            onClick={handleLoadMore}
          >
            Xem thêm voucher
          </button>
        </div>
      )}
    </div>
  );
};

export default VoucherShopeeList;
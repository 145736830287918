import { configureStore } from '@reduxjs/toolkit';
import changePasswordReducer from './user/changePasswordSlice';
import forgotPasswordReducer from './user/forgotPasswordSlice';
import loginReducer from './user/loginSlice';
import profileReducer from './user/profileSlice';
import registerReducer from './user/registerSlice';
import resetPasswordReducer from './user/resetPasswordSlice';
import verifyEmailReducer from './user/verifyEmailSlice';
import userTransactionReducer from './user/userTransactionSlice';
import getGiftsReducer from './user/getGiftSlice';
import productLazadaReducer from './shopcashbacks/getInfoProductLazadaSlice';
import productShopeeReducer from './shopcashbacks/getInfoProductShopeeSlice';
import productAccesstradeReducer from './shopcashbacks/getUrlProductAccesstradeSlice';
import voucherShopeeReducer from './vouchers/showVoucherShopeeSlice';
import voucherLazadaReducer from './vouchers/showVoucherLazadaSlice';

const store = configureStore({
  reducer: {
    changePassword: changePasswordReducer,
    forgotPassword: forgotPasswordReducer,
    login: loginReducer,
    profile: profileReducer,
    register: registerReducer,
    resetPassword: resetPasswordReducer,
    verifyEmail: verifyEmailReducer,
    userTransaction: userTransactionReducer,
    getGifts: getGiftsReducer,
    productLazada: productLazadaReducer,
    productShopee: productShopeeReducer,
    productAccesstrade: productAccesstradeReducer,
    voucherShopee: voucherShopeeReducer,
    voucherLazada: voucherLazadaReducer,
  },
});

export default store;
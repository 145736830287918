import axios from 'axios';
import { BASE_URL } from '../configs';

export const googleLogin = async (token) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/google`, { token });
    return response.data;
  } catch (error) {
    throw error;
  }
};
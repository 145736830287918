import { useState, useEffect } from 'react';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { googleLogin } from '../../api/user/authGoogleApi';
import Cookies from 'js-cookie';

const GoogleOneTapLogin = () => {
  const [shouldUseOneTap, setShouldUseOneTap] = useState(false);

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      setShouldUseOneTap(true);
    }
  }, []);

  return shouldUseOneTap ? <GoogleOneTap /> : null;
};

const GoogleOneTap = () => {
  useGoogleOneTapLogin({
    onSuccess: async (response) => {
      const { credential } = response;
      try {
        const data = await googleLogin(credential);

        // Lưu token và user vào cookies
        Cookies.set('token', data.token, { expires: 365 }); // token hết hạn sau 365 ngày
        Cookies.set('user', JSON.stringify(data.user), { expires: 365 });

        // Chuyển hướng người dùng tới trang chính
        window.location.href = '/';
      } catch (error) {
        console.error('Google login failed', error);
      }
    },
    onError: (error) => {
      console.error('Google login error', error);
    },
    enabled: true,
  });

  return null;
};

export default GoogleOneTapLogin;
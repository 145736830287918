import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { getUserThunk } from './store/user/profileSlice';

import MainLayout from './components/MainLayout';
import Home from './components/home/Home';
import ScrollToTop from './components/ScrollToTop';


import ShopeeCashback from './components/shopcashbacks/ShopeeCashback';
import TikTokShopCashback from './components/shopcashbacks/TikTokShopCashback';
import TikTokShopAddOrderCashback from './components/shopcashbacks/TikTokShopAddOrderCashback';
import AccesstradeCashback from './components/shopcashbacks/AccesstradeCashback';
import ListVouchers from './components/vouchers/ListVouchers';
import VoucherShopee from './components/vouchers/VoucherShopee';
import VoucherLazada from './components/vouchers/VoucherLazada';
import ListPost from './components/blog/ListPost';
import ViewPost from './components/blog/ViewPost';

import PrivateRoute from './components/user/PrivateRoute';
import AllShoppingCashback from './components/shopcashbacks/AllShoppingCashback';
import ShopeeSpinnerCoin from './components/other/ShopeeSpinnerCoin';

const LazadaCashback = lazy(() => import('./components/shopcashbacks/LazadaCashback'));
const Error = lazy(() => import('./components/other/Error'));
const RedirectPage = lazy(() => import('./components/other/RedirectPage'));
const About = lazy(() => import('./components/other/About'));
const Terms = lazy(() => import('./components/other/Terms'));
const Privacy = lazy(() => import('./components/other/Privacy'));
const Guides = lazy(() => import('./components/other/Guides'));
const RegisterForm = lazy(() => import('./components/user/RegisterForm'));
const VerifyEmailForm = lazy(() => import('./components/user/VerifyEmailForm'));
const LoginForm = lazy(() => import('./components/user/LoginForm'));
const ForgotPasswordForm = lazy(() => import('./components/user/ForgotPasswordForm'));
const ResetPasswordForm = lazy(() => import('./components/user/ResetPasswordForm'));
const Profile = lazy(() => import('./components/user/Profile'));
const ChangePassword = lazy(() => import('./components/user/ChangePassword'));
const ShowMyOrder = lazy(() => import('./components/user/ShowMyOrder'));
const WithdrawForm = lazy(() => import('./components/user/WithdrawForm'));

const App = () => {
    const dispatch = useDispatch();
    const token = Cookies.get('token');
    const user = useSelector((state) => state.profile.user);

    useEffect(() => {
        if (token) {
            dispatch(getUserThunk());
        }
    }, [dispatch, token]);

    return (
        <BrowserRouter>
            <MainLayout>
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/gioi-thieu" element={<Suspense><About /></Suspense>} />
					<Route path="/dieu-khoan" element={<Suspense><Terms /></Suspense>} />
					<Route path="/chinh-sach" element={<Suspense><Privacy /></Suspense>} />
                    <Route path="/huong-dan" element={<Suspense><Guides /></Suspense>} />
                    <Route path="/shopee" element={<ShopeeCashback />} />
                    <Route path="/lazada" element={<Suspense><LazadaCashback /></Suspense>} />
					<Route path="/tiktokshop" element={<TikTokShopCashback />} />
					<Route path="/tiktokshop/add" element={<TikTokShopAddOrderCashback />} />
                    <Route path="/cashback-all" element={<AccesstradeCashback />} />
                    <Route path="/hoan-tien" element={<AllShoppingCashback />} />
                    <Route path="/shopee-xu" element={<ShopeeSpinnerCoin />} />
                    <Route path="/vouchers" element={<ListVouchers />} />
                    <Route path="/voucher-shopee" element={<VoucherShopee />} />
                    <Route path="/voucher-lazada" element={<VoucherLazada />} />
                    <Route path="/blog" element={<ListPost />} />
                    <Route path="/bai-viet/:slug" element={<ViewPost />} />
                    <Route path="/register" element={<Suspense><RegisterForm /></Suspense>} />
                    <Route path="/verify-email" element={<Suspense><VerifyEmailForm /></Suspense>} />
                    <Route path="/login" element={<Suspense><LoginForm /></Suspense>} />
                    <Route path="/forgot-password" element={<Suspense><ForgotPasswordForm /></Suspense>} />
                    <Route path="/reset-password/:token" element={<Suspense><ResetPasswordForm /></Suspense>} />
                    <Route element={<PrivateRoute />}>
                        <Route path="/profile" element={<Suspense><Profile /></Suspense>} />
                        <Route path="/my-order" element={<Suspense><ShowMyOrder idUser={user?.idUser} /></Suspense>} />
                        <Route path="/withdraw" element={<Suspense><WithdrawForm /></Suspense>} />
                        <Route path="/change-password" element={<Suspense><ChangePassword /></Suspense>} />
                    </Route> 
                    <Route path="/redirect" element={<Suspense><RedirectPage /></Suspense>} />         
                    <Route path="*" element={<Suspense><Error /></Suspense>} />         
                </Routes>
            </MainLayout>
        </BrowserRouter>
    );
};

export default App;
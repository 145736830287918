const BASE_URL = process.env.REACT_APP_BASE_URL;
const SUPPORT_URL = process.env.REACT_APP_SUPPORT_URL;
const ZALO_URL = process.env.REACT_APP_ZALO_URL; // Link liên hệ Zalo
const GROUP_ZALO_URL = process.env.REACT_APP_GROUP_ZALO_URL; // Link group Zalo săn voucher
const GROUP_TELEGRAM_URL = 'https://t.me/hoisanvoucher'; // Link group Telegram săn voucher

const IMAGES_SLIDES = [
    { src: '/images/imgslides/slide-hoan-tien.png', link: '/hoan-tien' },
    { src: '/images/imgslides/slide-hoan-tien-2.png', link: '/huong-dan' },
  ];

export { BASE_URL, SUPPORT_URL, ZALO_URL, IMAGES_SLIDES, GROUP_ZALO_URL, GROUP_TELEGRAM_URL };
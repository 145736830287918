import React from 'react';
import { Link } from 'react-router-dom';
import SearchVideoShopee from '../other/SearchVideoShopee';
import ListVoucherShopee from './ListVoucherShopee';
import SEOMeta from '../SEOMeta';
import {formattedMonthYear, formattedDate} from '../utils/formatTimeNow';
import BoxJoinGroup from '../other/BoxJoinGroup';

const VoucherShopee = () => {

    return (
        <>
            <SEOMeta 
                title={`Mã giảm giá Shopee mới nhất ${formattedMonthYear} - Mã freeship toàn sàn`}
                description={`Cập nhật mã giảm giá Shopee mới nhất hôm nay. Tiết lộ trước các voucher Shopee, Shopee live, Shopee video và mã freeship tháng ${formattedMonthYear}.`}
            />
            <p className="my-3">Ở đây, chúng tôi cập nhật liên tục các <b>mã giảm giá Shopee</b> toàn sàn. Chỉ đơn giản với vài thao tác click chuột là bạn đã có thể lấy được voucher Shopee mới nhất.</p>
            <p className="my-3">Bất cứ khi nào bạn muốn mua hàng trên Shopee, hãy truy cập <b>Tui3Gang.Com</b> để lấy mã giảm giá nhận hoàn tiền từ mỗi đơn hàng.</p>
            <div className="p-1 border rounded-md shadow-md flex flex-col bg-white">
                <div className="flex">
                    <div className="w-1/4 flex flex-col items-center justify-center">
                        <img src="/images/logo-shopee-coupon.png" alt="Hoàn tiền Shopee" className="w-11 h-auto rounded-xl mb-1 bg-[#ee4d2d]" />
                        <p className="text-md">Shopee</p>
                    </div>
                    <div className="w-full px-2 flex flex-col justify-between">
                        <p className="text-md font-bold">Hoàn tiền đến 50% giá trị đơn hàng</p>
                        <div className="flex justify-between">
                            <div className="items-start">
                            <p className="text-sm text-gray-500">#Áp dụng với tất cả user</p>
                            <a href="/huong-dan" className="text-sm text-green-700 underline">Xem hướng dẫn</a>
                            </div>
                            <div className="text-sm flex items-center justify-end">
                                <Link to="/shopee">
                                    <button className="text-green-700 font-medium px-4 py-1 rounded bg-green-100 getvoucher">Nhận ngay</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className="text-2xl font-semibold my-2 p-2">Mã giảm giá Shopee ngày {formattedDate}</p>
            <SearchVideoShopee />
            <ListVoucherShopee />
            <BoxJoinGroup />
        </>
    );
};

export default VoucherShopee;
import React from 'react';
import HomeWelcome from './HomeWelcome';
import ShoppingCashback from './ShoppingCashback';
import VoucherShopeeList from '../vouchers/ListVoucherShopee';
import VoucherLazadaList from '../vouchers/ListVoucherLazada';
// import TopUsers from '../user/TopUserMonth'; // Hiển thị component Top hoàn tiền
import AffiliatedPartners from './AffiliatedPartners';
import SEOMeta from '../../components/SEOMeta';

const Home = () => {

  const logoBrands = [
    { src: '/images/brands/logo-lazada.png' },
    { src: '/images/brands/logo-shopee.png' },
    { src: '/images/brands/logo-tiki.png' },
    { src: '/images/brands/logo-tiktokshop.png' },
    { src: '/images/brands/logo-dienmayxanh.png' },
    { src: '/images/brands/logo-thegioididong.png' },
    { src: '/images/brands/logo-nhathuoclongchau.png' },
    { src: '/images/brands/logo-fpt-shop.png' },
    { src: '/images/brands/logo-adidas.png' },
    { src: '/images/brands/logo-watsons.png' },
    { src: '/images/brands/logo-th-true-milk.png' },
    { src: '/images/brands/logo-mia.png' },
    { src: '/images/brands/logo-juno.png' },
    { src: '/images/brands/logo-fahasa.png' },
    { src: '/images/brands/logo-concung.png' },
  ];

  return (
    <>
        <SEOMeta 
          title="Túi 3 Gang - Hoàn Tiền Shopee, Lazada, TikTok Shop Đến 60%"
          description="Mua sắm trên Shopee, Lazada, TikTok Shop, Tiki,... nhận hoàn tiền cùng Tui3Gang.Com. Hoàn tiền lên tới 60% là có thật!"
        />
        <HomeWelcome />
        <ShoppingCashback />
        <p className="text-center text-xl text-white mb-5 p-2 rounded-lg bg-green-700">Mã giảm giá Shopee</p>
        <VoucherShopeeList />
        <p className="text-center text-xl text-white mb-5 p-2 rounded-lg bg-green-700">Mã giảm giá Lazada</p>
        <VoucherLazadaList />
        {/* <TopUsers /> Hiển thị bảng TOP 10 hoàn tiền */}
        <AffiliatedPartners images={logoBrands} />
    </>
  );
};

export default Home;
import React, { useState, useEffect } from 'react';

const messages = [
    { title: 'Nguyễn Hữu Chiến', content: 'vừa lấy voucher Shopee' },
    { title: 'Lê Minh An', content: 'vừa đặt 1 đơn Lazada' },
    { title: 'Dương Bảo Chấn', content: 'vừa đặt 2 đơn Shopee ' },
    { title: 'Trần Trung Nghĩa', content: 'vừa đặt 1 đơn Tiki' },
    { title: 'Vũ Thị Thu', content: 'vừa đặt 1 đơn Điện Máy Xanh' },
    { title: 'Dương Thị Hảo ', content: 'vừa đặt 1 đơn Lazada ' },
    { title: 'Bùi Quang Tiến', content: 'vừa đặt 1 đơn FPT Shop' },
    { title: 'Vũ Tiến Tùng', content: 'vừa lấy voucher Lazada' },
    { title: 'Lâm Thị Thủy', content: 'vừa đặt 1 đơn nhà thuốc Long Châu' },
    { title: 'Mai Thùy Dương', content: 'vừa lấy voucher Shopee' },
    { title: 'Lã Kiều Tuyết', content: 'vừa đặt 3 đơn Concung' },
    { title: 'Lê Thị Hoa', content: 'vừa đặt 1 đơn Fahasa' },
    { title: 'Trần Tiến Dũng', content: 'vừa đặt 1 đơn Điện Máy Xanh' },
    { title: 'Vũ Văn Lâm', content: 'vừa đặt 1 đơn Mytour' },
    { title: 'Tạ Thị Thủy', content: 'vừa lấy voucher Lazada' },
    { title: 'Đặng Tiến', content: 'vừa đặt 1 đơn Traveloka' },
    { title: 'Lâm Thị Thanh', content: 'vừa đặt 2 đơn TheFaceShop' },
    { title: 'Chu Thị Thu', content: 'vừa đặt 1 đơn Shopee ' },
    { title: 'Nguyen Thi Nhan', content: 'vừa đặt 1 đơn THShop' },
    { title: 'Dang Thi Xuan Hau', content: 'vừa đặt 1 đơn Lazada ' },
    { title: 'Khuất Thi Hương', content: 'vừa đặt 1 đơn Watsons' },
    { title: 'Trương Thị Dung', content: 'vừa lấy voucher Shopee' },
    { title: 'Nguyễn Bá Dũng', content: 'vừa đặt 2 đơn Shopee ' },
    { title: 'Trần Bảo', content: 'vừa lấy voucher Lazada' },
    { title: 'Trần Thị Yến Trâm', content: 'vừa đặt 1 đơn Tiki' },
    { title: 'Thắng', content: 'vừa đặt 3 đơn Shopee ' },
    { title: 'Duy Lâm', content: 'vừa đặt 1 đơn Lazada ' },
    { title: 'Lê Diệu Linh', content: 'vừa đặt 1 đơn nhà thuốc Long Châu' },
    { title: 'Nguyen Thi Lan', content: 'vừa lấy voucher Shopee' },
    { title: 'Hồ Đình Diện', content: 'vừa đặt 1 đơn Concung' },
    { title: 'Nguyễn Thị Nga', content: 'vừa lấy voucher Lazada' },
    { title: 'Le Danh Hue', content: 'vừa lấy voucher Shopee' },
    { title: 'Hoa Nguyễn', content: 'vừa đặt 2 đơn Lazada ' },
    { title: 'Trương Thị Dung', content: 'vừa đặt 1 đơn Tiki' },
    { title: 'Dương Văn Dũng', content: 'vừa đặt 1 đơn Shopee ' },
    { title: 'Đỗ Huy Vũ', content: 'vừa lấy voucher Shopee' },
    { title: 'Vũ Ngọc Thái', content: 'vừa đặt 2 đơn Lazada ' },
];

const Notification = () => {
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState({ title: '', content: '' });

  useEffect(() => {
    const showNotification = () => {
      const randomIndex = Math.floor(Math.random() * messages.length);
      setMessage(messages[randomIndex]);
      setVisible(true);
      setTimeout(() => setVisible(false), 3000);
    };

    showNotification(); // Hiển thị thông báo đầu tiên

    const interval = setInterval(() => {
      showNotification();
    }, Math.random() * (50000 - 10000) + 8000); // Random từ 8-50 giây

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`fixed top-1 right-1 bg-white border border-gray-300 rounded-[30px] py-1 px-5 shadow-md flex items-center w-auto max-w-80 transition-opacity duration-500 ${visible ? 'opacity-100' : 'opacity-0'}`}>
      <img src="/images/logo-tui3gang.png" alt="Thông báo" className="w-12 h-12 mr-2" />
      <div>
        <div className="font-bold">{message.title}</div>
        <div className="text-sm">{message.content}</div>
      </div>
    </div>
  );
};

export default Notification;